import { downloadByGet, get, post } from '@/libs/axios'

// eslint-disable-next-line import/prefer-default-export
// 角色列表
export const GetRoleList = () => get('/system/role/GetRoleList')
// 角色下拉
export const QueryRoleOptions = () => get('/system/role/QueryRoleOptions')
export const resourceListById = id => get(`/system/role/ResourceList?roleId=${id}`)
// 角色用户列表
export const ListRoleUser = params => post('/system/role/ListRoleUser', params)
export const ImportRole = params => post('/system/role/import', params)
export const DownloadRoleTemolate = () => downloadByGet(`/system/role/import_template?_t=${Date.parse(new Date())}`)
// 角色授权
export const CreateRoleResource = params => post('/system/role/SaveRoleResource', params)
// 添加角色用户
export const AddRoleUser = params => post('/system/role/AddRoleUser', params)
// 删除角色用户
export const DelRoleUser = params => post('/system/role/DelRoleUser', params)
// 删除角色
export const DeleteRole = params => post('/system/role/DeleteRole', params)

// 添加修改角色
export const saveOrUpdateRole = params => {
  const submitUrl = params.id ? '/system/role/UpdateRole' : '/system/role/CreateRole'
  return post(submitUrl, params)
}
