import { get, post } from '@/libs/axios'

// 查询资源
export const ListResource = () => get('/system/ListResource')
// 查询资源接口
export const ListResourcePerm = id => get(`/system/ListResourcePerm?resourceId=${id}`)
export const DeletePerms = id => post('/system/resource/DeletePerms', id)
// 查询接口列表
export const GetRoutePathList = () => get('/system/GetRoutePathList')
// 添加资源权限
export const AddResourcePerms = params => post('/system/AddResourcePerms', params)
// 删除资源
export const DeleteResourceByIds = formData => post('/system/DeleteResourceByIds', formData)
// 添加资源或修改资源
export const saveOrUpdateResource = params => {
  const submitUrl = params.id ? '/system/UpdateResource' : '/system/AddResource'
  return post(submitUrl, params)
}
